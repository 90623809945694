import React from "react";
import { H1, Text, Container, Image } from "./SuccessSiteCss";
import { Link, ActionButton } from "../styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const SuccessSite = () => (
  <Container>
    <Image src="../assets/ikona.png" />
    <H1>Sukces!</H1>
    <Text>Dziękujemy za wypełnienie formularza.</Text>
    <Text>Nasz konsultant skontaktuje się w wyznaczonym terminie</Text>
    <Link to="/" style={{ marginTop: "2vh" }}>
      <ActionButton variant="outlined" startIcon={<ArrowBackIcon />}>
        wróć
      </ActionButton>
    </Link>
  </Container>
);

export default SuccessSite;
