import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import SelectOption from "./components/SelectOption";
import CarOptions from "./components/CarOptions/CarOptions";
import CarOcForm from "./components/Forms/CarOcForm/CarOcForm";
import SuccessSite from "./components/SuccessSite/SuccessSite";

const App = () => {
  return (
    <div style={{ height: "90vh" }}>
      <Router>
        <Switch>
          {/* <Route path="/" component={SuccessSite} /> */}
          <Route path="/carinsurance/oc" component={CarOcForm} />
          <Route path="/carinsurance" component={CarOptions} />
          <Route path="/success" component={SuccessSite} />
          <Route path="/" component={SelectOption} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
