import emailjs from "emailjs-com";

export const sendEmail = (data) => {
  return emailjs.send(
    "service_uwimm4e",
    "template_h9ngt53",
    {
      email_data: data,
      reply_to: "grzegorz.pukownik@phinance.pl",
    },
    "user_tXSIHfj93PnUzRkh8tRTM"
  );
};
