import { Typography, Button, Grid, CircularProgress } from "@material-ui/core";
import { white } from "../appColors";
import styled from "styled-components";

export const H1 = styled.h1`
  color: ${white};
`;
export const Text = styled(Typography)`
  && {
    margin-bottom: 2vw;
  }
  color: ${white};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin: 10px;
`;
export const Image = styled.img`
  width: 30vw;
`;
